import {useEffect, useRef, useState} from 'react';

export const useFirstViewportEntry = <T extends HTMLElement>(
  observerOptions?: IntersectionObserverInit
) => {
  const ref = useRef<T>(null);
  const [entered, setEntered] = useState(false);
  const observer = useRef(
    new IntersectionObserver(
      ([entry]) => setEntered(entry.isIntersecting),
      observerOptions
    )
  );

  useEffect(() => {
    const element = ref.current;
    const ob = observer.current;

    // Stop observing when the element is first in the viewport
    if (entered) {
      ob.disconnect();
      return;
    }

    if (element && !entered) {
      ob.observe(element);
    }

    return () => {
      ob.disconnect();
    };
  }, [entered, ref]);

  return [ref, entered] as const;
};
