export const BASENAME = '/';
export const ApiStoreLink = 'web-scraping-api-store';
export const ApiDetailsLink = (scraperUrl: string) => `scraper/${scraperUrl}`;
export const BlogLink = 'web-scraping-blog';
export const BlogPostLink = (postUrl: string) => `post/${postUrl}`;
export const OnlineApiTestLink = 'online-test';
export const DocumentationLink = (sectionPage?: string) =>
  sectionPage
    ? `web-scraping-documentation/${sectionPage}`
    : 'web-scraping-documentation';
export const ContactLink = 'contact-us';
export const PricingLink = 'pricing-web-scraper-api';

// Scraper links
export const RAW_HTML_LINK = 'raw-html';
export const SEARCH_ENGINE_LINK = 'web-scraping-api-search-engine';
export const ECOMMERCE_LINK = 'ecommerce';
export const REAL_ESTATE_LINK = 'web-scraping-api-real-estate';
export const LINKEDIN_LINK = 'linkedin';
export const INSTAGRAM_LINK = 'instagram';
export const FACEBOOK_LINK = 'facebook';
export const TIKTOK_LINK = 'tiktok';
export const THREADS_LINK = 'threads';
export const TWITTER_LINK = 'web-scraping-api-twitter';
export const YIELDBOOKING_LINK = 'yieldbooking.com';
export const PRESTASHOP_LINK = 'prestashop';
export const FUNDA_LINK = 'web-scraping-api-funda';
export const CDISCOUNT_LINK = 'cdiscount';
export const AMAZON_LINK = 'amazon';
export const RAKUTEN_LINK = 'rakuten';
export const FNAC_LINK = 'fnac';
export const SEPHORA_LINK = 'sephora';
export const LEBONCOIN_LINK = 'leboncoin';
export const WOODBRASS_LINK = 'woodbrass';
export const FOSSIL_LINK = 'fossil';
export const SUBITO_LINK = 'subito';
export const BESTBUY_LINK = 'bestbuy';
export const ZARA_LINK = 'zara';
export const GOG_LINK = 'gog';
export const MAISONDUMONDE_LINK = 'maisondumonde';
export const IMMOBILIENSCOUT_LINK = 'immobilienscout';
export const DELL_LINK = 'dell';
export const BOSE_LINK = 'bose';
export const BOULANGER_LINK = 'boulanger';
export const BOL_LINK = 'bol';
export const LAREDOUTE_LINK = 'la-redoute';
export const RIGHTMOVE_LINK = 'rightmove';
export const HARDWARE_LINK = 'hardware.fr';
export const IDEALISTA_LINK = 'idealista';
export const IMMOWEB_LINK = 'web-scraping-api-immoweb';
export const BIENICI_LINK = 'web-scraping-api-bien-ici';
export const SELOGER_LINK = 'web-scraping-api-se-loger';
export const KAUFPARTS_LINK = 'web-scraping-api-kaufparts';
export const CRAIGSLIST_LINK = 'web-scraping-api-craigslist';
export const IGVAULT_LINK = 'web-scraping-api-igvault';
export const ENTREPARTICULIERS_LINK = 'web-scraping-api-entreparticuliers';
export const MATERIELNET_LINK = 'web-scraping-api-materielnet';
export const EBAY_LINK = 'web-scraping-api-ebay';
export const ALIEXPRESS_LINK = 'web-scraping-api-aliexpress';
export const FACEBOOK_POST_LINK = 'web-scraping-api-facebook-posts';
export const FACEBOOK_JOB_OFFERS_LINK = 'web-scraping-api-facebook-job-offers';
export const FACEBOOK_BUSINESS_PAGE_LINK =
  'web-scraping-api-facebook-business-page';
export const FACEBOOK_PROFILE_LINK = 'web-scraping-api-facebook-profile';
export const LINKEDIN_POST_LINK = 'web-scraping-api-linkedin-posts';
export const LINKEDIN_BUSINESS_PROFILES_LINK =
  'web-scraping-api-linkedin-business-profiles';
export const LINKEDIN_URLS_LINK = 'linkedin-urls';
export const LINKEDIN_PROFILE_LINK = 'linkedin-profiles';
export const INSTAGRAM_POST_LINK = 'web-scraping-api-instagram-posts';
export const INSTAGRAM_HASHTAGS_LINK = 'web-scraping-api-instagram-hashtags';
export const INSTAGRAM_PROFILES_LINK = 'instagram-profiles';
export const TIKTOK_PROFILES_LINK = 'tiktok-profiles';
export const TIKTOK_HASHTAGS_LINK = 'tiktok-hashtags';

// Post links
export const EMAIL_SCRAPING_POST_LINK = 'email-scraping';
export const ECOMMERCE_POST_LINK =
  'title-price-scraping-and-price-monitoring-gaining-a-competitive-edge-with-scraping-bot';
export const HOW_TO_SCRAPE_THREADS_POST_LINK =
  'how-to-scrape-and-collect-data-from-threads-2';
export const REAL_ESTATE_POST_LINK = 'real-estate';
export const BEST_FREE_SCRAPING_TOOLS_POST_LINK =
  '45-the-best-free-scraping-tools';
export const HOW_TO_SCRAPE_TWITTER_POST_LINK =
  'how-to-scrape-and-collect-data-from-twitter';
export const SPORTS_BETTING_POST_LINK =
  'unleashing-the-power-of-data-in-sports-betting-with-scraping-bot-io';
export const BEST_WEB_SCRAPING_TOOLS_POST_LINK =
  'top-6-best-scraping-tools-to-collect-data-from-a-webpage';
export const APARTMENTS_COM_SCRAPER_POST_LINK = 'scraper-for-apartments-com';
export const HOW_TO_SCRAPE_FUNDA_POST_LINK =
  'how-to-scrape-real-estate-listings-on-funda';
export const WEB_SCRAPING_VS_WEB_CRAWLING_POST_LINK =
  'differences-between-web-crawling-and-web-scraping';
export const HOW_TO_SCRAPE_FACEBOOK_POST_LINK =
  'how-to-scrape-and-collect-data-from-facebook';
export const HOW_TO_BUILD_A_WEB_CRAWLER_POST_LINK =
  'how-to-build-a-web-crawler';
export const HOW_TO_SCRAPE_WITH_POSTMAN_POST_LINK =
  'scraping-with-postman-how-to';
export const HOW_TO_SCRAPE_WEBSITE_WITHOUT_GETTING_BLOCKED_POST_LINK =
  '4-things-to-know-about-web-automation';
export const BEST_WEB_SCRAPING_TIPS_POST_LINK = 'top-7-web-scraping-tips';
export const TOP_5_PROXY_PROVIDERS_POST_LINK =
  'top-5-best-proxy-providers-for-web-scraping';
export const HOW_TO_SCRAPE_TIKTOK_POST_LINK =
  'how-to-scrape-and-collect-data-from-tiktok';
export const HOW_TO_SCRAPE_INSTAGRAM_POST_LINK =
  'how-to-scrape-and-collect-data-from-instagram';
export const HOW_TO_SCRAPE_LINKEDIN_POST_LINK =
  'how-to-scrape-and-collect-data-from-linkedin';
export const HOW_TO_SCRAPE_INFINITE_SCROLL_PAGES_POST_LINK =
  'how-to-scrape-infinite-scroll-pages';
export const HOW_TO_SCRAPE_WALMART_POST_LINK =
  'how-to-scrape-product-listings-from-walmart';
export const HOW_TO_SCRAPE_RAKUTEN_POST_LINK =
  'how-to-scrape-product-listings-from-rakuten';
export const HOW_TO_SCRAPE_LEBONCOIN_POST_LINK =
  'how-to-scrape-real-estate-listings-from-leboncoin';
export const HOW_TO_SCRAPE_ZILLOW_POST_LINK =
  'how-to-scrape-real-estate-listings-on-zillow';
export const ANTI_SCRAPING_METHODS_POST_LINK = 'anti-scraping-methods';
export const HOW_TO_SCRAPE_RIGHTMOVE_POST_LINK =
  'how-to-scrape-real-estate-listings-on-rightmove';
export const HOW_TO_SCRAPE_EBAY_POST_LINK =
  'how-to-scrape-an-ebay-product-page';
export const NEW_FEATURE_LIVE_TEST_POST_LINK =
  'new-feature-live-test-web-scraping';
export const HOW_TO_SCRAPE_WITHOUT_GETTING_BLOCKED_POST_LINK =
  'how-to-scrape-a-website-without-getting-blocked';
export const BETTER_SCRAPING_BOT_POST_LINK =
  'api-scraping-tool-for-ecommerce-and-online-shopping-sites';
