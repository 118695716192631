import {ReactNode, Suspense} from 'react';
import {useFirstViewportEntry} from '../hooks/useFirstViewportEntry';
import {Loading} from './Loading';

type RenderOnScrollProps = {
  children: ReactNode;
  threshold?: number;
  root?: Element | null;
  rootMargin?: string;
  height: number | string;
  wrapperProps?: Record<string, unknown>;
};

export const RenderOnScroll = ({
  children,
  threshold = 0.1,
  root = null,
  rootMargin = '100px 0px 100px 0px',
  height,
  ...wrapperProps
}: RenderOnScrollProps) => {
  const [ref, entered] = useFirstViewportEntry<HTMLDivElement>({
    threshold,
    root,
    rootMargin,
  });

  return (
    <div {...wrapperProps} ref={ref}>
      {entered && (
        <Suspense fallback={<Loading height={height} />}>{children}</Suspense>
      )}
    </div>
  );
};
