import {createBrowserRouter} from 'react-router-dom';
import {Layout} from './components/Layout';
import {ErrorHandler} from './components/ErrorHandler';
import {
  LazyApiStore,
  LazyBlog,
  LazyBlogPost,
  LazyContact,
  LazyDocumentation,
  LazyHome,
  LazyOnlineApiTest,
  LazyErrorPage,
  LazyPricing,
  LazyRawHtmlScraper,
  LazySearchEngineScraper,
  LazyEcommerceScraper,
  LazyRealEstateScraper,
  LazyLinkedInScraper,
  LazyInstagramScraper,
  LazyFacebookScraper,
  LazyTiktokScraper,
  LazyThreadsScraper,
  LazyTwitterScraper,
  LazyYieldbooking,
  LazyPrestashop,
  LazyFundaScraper,
  LazyCdiscountScraper,
  LazyAmazonScraper,
  LazyRakutenScraper,
  LazyFnacScraper,
  LazySephoraScraper,
  LazyLeboncoinScraper,
  LazyWoodbrassScraper,
  LazyFossilScraper,
  LazySubitoScraper,
  LazyBestbuyScraper,
  LazyZaraScraper,
  LazyGogScraper,
  LazyMaisonDuMondeScraper,
  LazyImmobilienscoutScraper,
  LazyDellScraper,
  LazyBoseScraper,
  LazyBoulangerScraper,
  LazyBolScraper,
  LazyLaRedouteScraper,
  LazyRightmoveScraper,
  LazyHardwareScraper,
  LazyIdealistaScraper,
  LazyImmowebScraper,
  LazyBienIciScraper,
  LazySeLogerScraper,
  LazyKaufpartsScraper,
  LazyCraigslistScraper,
  LazyIGVaultScraper,
  LazyEntreParticuliersScraper,
  LazyMaterielNetScraper,
  LazyEbayScraper,
  LazyAliexpressScraper,
  LazyFacebookPostScraper,
  LazyFacebookJobsScraper,
  LazyFacebookBusinessPageScraper,
  LazyFacebookProfileScraper,
  LazyLinkedInPostScraper,
  LazyLinkedInBusinessProfilesScraper,
  LazyLinkedinUrlsScraper,
  LazyLinkedinProfileScraper,
  LazyInstagramPostScraper,
  LazyInstagramHashtagsScraper,
  LazyInstagramProfilesScraper,
  LazyTiktokProfilesScraper,
  LazyTiktokHashtagsScraper,
  LazyEmailScraping,
  LazyPricingWeb,
  LazyHowToScrapeThreads,
  LazyScrapingBotRealEstate,
  LazyBestWebScrapingTools,
  LazyHowToScrapeTwitter,
  LazyDataSportsBetting,
} from './components/lazy/LazyComponents';

import {
  ApiDetailsLink,
  ApiStoreLink,
  BASENAME,
  BlogLink,
  BlogPostLink,
  ContactLink,
  OnlineApiTestLink,
  PricingLink,
  DocumentationLink,
  RAW_HTML_LINK,
  SEARCH_ENGINE_LINK,
  ECOMMERCE_LINK,
  REAL_ESTATE_LINK,
  LINKEDIN_LINK,
  INSTAGRAM_LINK,
  FACEBOOK_LINK,
  TIKTOK_LINK,
  THREADS_LINK,
  TWITTER_LINK,
  YIELDBOOKING_LINK,
  PRESTASHOP_LINK,
  FUNDA_LINK,
  CDISCOUNT_LINK,
  AMAZON_LINK,
  RAKUTEN_LINK,
  FNAC_LINK,
  SEPHORA_LINK,
  LEBONCOIN_LINK,
  WOODBRASS_LINK,
  FOSSIL_LINK,
  SUBITO_LINK,
  BESTBUY_LINK,
  ZARA_LINK,
  GOG_LINK,
  MAISONDUMONDE_LINK,
  IMMOBILIENSCOUT_LINK,
  DELL_LINK,
  BOSE_LINK,
  BOULANGER_LINK,
  BOL_LINK,
  LAREDOUTE_LINK,
  RIGHTMOVE_LINK,
  HARDWARE_LINK,
  IDEALISTA_LINK,
  IMMOWEB_LINK,
  BIENICI_LINK,
  SELOGER_LINK,
  KAUFPARTS_LINK,
  CRAIGSLIST_LINK,
  IGVAULT_LINK,
  ENTREPARTICULIERS_LINK,
  MATERIELNET_LINK,
  EBAY_LINK,
  ALIEXPRESS_LINK,
  FACEBOOK_POST_LINK,
  FACEBOOK_JOB_OFFERS_LINK,
  FACEBOOK_BUSINESS_PAGE_LINK,
  FACEBOOK_PROFILE_LINK,
  LINKEDIN_POST_LINK,
  LINKEDIN_BUSINESS_PROFILES_LINK,
  LINKEDIN_URLS_LINK,
  LINKEDIN_PROFILE_LINK,
  INSTAGRAM_POST_LINK,
  INSTAGRAM_HASHTAGS_LINK,
  INSTAGRAM_PROFILES_LINK,
  TIKTOK_PROFILES_LINK,
  TIKTOK_HASHTAGS_LINK,
  EMAIL_SCRAPING_POST_LINK,
  ECOMMERCE_POST_LINK,
  HOW_TO_SCRAPE_THREADS_POST_LINK,
  REAL_ESTATE_POST_LINK,
  BEST_FREE_SCRAPING_TOOLS_POST_LINK,
  HOW_TO_SCRAPE_TWITTER_POST_LINK,
  SPORTS_BETTING_POST_LINK,
} from './utils/constants';

export const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Layout />,
      errorElement: <ErrorHandler />,
      children: [
        {
          path: '/',
          element: <LazyHome />,
        },
        {
          path: ApiStoreLink,
          element: <LazyApiStore />,
        },
        {
          path: ApiDetailsLink(TIKTOK_HASHTAGS_LINK),
          element: <LazyTiktokHashtagsScraper />,
        },
        {
          path: ApiDetailsLink(TIKTOK_PROFILES_LINK),
          element: <LazyTiktokProfilesScraper />,
        },
        {
          path: ApiDetailsLink(INSTAGRAM_PROFILES_LINK),
          element: <LazyInstagramProfilesScraper />,
        },
        {
          path: ApiDetailsLink(INSTAGRAM_HASHTAGS_LINK),
          element: <LazyInstagramHashtagsScraper />,
        },
        {
          path: ApiDetailsLink(INSTAGRAM_POST_LINK),
          element: <LazyInstagramPostScraper />,
        },
        {
          path: ApiDetailsLink(LINKEDIN_PROFILE_LINK),
          element: <LazyLinkedinProfileScraper />,
        },
        {
          path: ApiDetailsLink(LINKEDIN_URLS_LINK),
          element: <LazyLinkedinUrlsScraper />,
        },
        {
          path: ApiDetailsLink(LINKEDIN_BUSINESS_PROFILES_LINK),
          element: <LazyLinkedInBusinessProfilesScraper />,
        },
        {
          path: ApiDetailsLink(LINKEDIN_POST_LINK),
          element: <LazyLinkedInPostScraper />,
        },
        {
          path: ApiDetailsLink(FACEBOOK_PROFILE_LINK),
          element: <LazyFacebookProfileScraper />,
        },
        {
          path: ApiDetailsLink(FACEBOOK_BUSINESS_PAGE_LINK),
          element: <LazyFacebookBusinessPageScraper />,
        },
        {
          path: ApiDetailsLink(FACEBOOK_JOB_OFFERS_LINK),
          element: <LazyFacebookJobsScraper />,
        },
        {
          path: ApiDetailsLink(FACEBOOK_POST_LINK),
          element: <LazyFacebookPostScraper />,
        },
        {
          path: ApiDetailsLink(ALIEXPRESS_LINK),
          element: <LazyAliexpressScraper />,
        },
        {
          path: ApiDetailsLink(EBAY_LINK),
          element: <LazyEbayScraper />,
        },
        {
          path: ApiDetailsLink(MATERIELNET_LINK),
          element: <LazyMaterielNetScraper />,
        },
        {
          path: ApiDetailsLink(ENTREPARTICULIERS_LINK),
          element: <LazyEntreParticuliersScraper />,
        },
        {
          path: ApiDetailsLink(IGVAULT_LINK),
          element: <LazyIGVaultScraper />,
        },
        {
          path: ApiDetailsLink(CRAIGSLIST_LINK),
          element: <LazyCraigslistScraper />,
        },
        {
          path: ApiDetailsLink(KAUFPARTS_LINK),
          element: <LazyKaufpartsScraper />,
        },
        {
          path: ApiDetailsLink(SELOGER_LINK),
          element: <LazySeLogerScraper />,
        },
        {
          path: ApiDetailsLink(BIENICI_LINK),
          element: <LazyBienIciScraper />,
        },
        {
          path: ApiDetailsLink(IMMOWEB_LINK),
          element: <LazyImmowebScraper />,
        },
        {
          path: ApiDetailsLink(IDEALISTA_LINK),
          element: <LazyIdealistaScraper />,
        },
        {
          path: ApiDetailsLink(HARDWARE_LINK),
          element: <LazyHardwareScraper />,
        },
        {
          path: ApiDetailsLink(RIGHTMOVE_LINK),
          element: <LazyRightmoveScraper />,
        },
        {
          path: ApiDetailsLink(LAREDOUTE_LINK),
          element: <LazyLaRedouteScraper />,
        },
        {
          path: ApiDetailsLink(BOL_LINK),
          element: <LazyBolScraper />,
        },
        {
          path: ApiDetailsLink(BOULANGER_LINK),
          element: <LazyBoulangerScraper />,
        },
        {
          path: ApiDetailsLink(BOSE_LINK),
          element: <LazyBoseScraper />,
        },
        {
          path: ApiDetailsLink(DELL_LINK),
          element: <LazyDellScraper />,
        },
        {
          path: ApiDetailsLink(IMMOBILIENSCOUT_LINK),
          element: <LazyImmobilienscoutScraper />,
        },
        {
          path: ApiDetailsLink(MAISONDUMONDE_LINK),
          element: <LazyMaisonDuMondeScraper />,
        },
        {
          path: ApiDetailsLink(GOG_LINK),
          element: <LazyGogScraper />,
        },
        {
          path: ApiDetailsLink(ZARA_LINK),
          element: <LazyZaraScraper />,
        },
        {
          path: ApiDetailsLink(BESTBUY_LINK),
          element: <LazyBestbuyScraper />,
        },
        {
          path: ApiDetailsLink(SUBITO_LINK),
          element: <LazySubitoScraper />,
        },
        {
          path: ApiDetailsLink(FOSSIL_LINK),
          element: <LazyFossilScraper />,
        },
        {
          path: ApiDetailsLink(WOODBRASS_LINK),
          element: <LazyWoodbrassScraper />,
        },
        {
          path: ApiDetailsLink(LEBONCOIN_LINK),
          element: <LazyLeboncoinScraper />,
        },
        {
          path: ApiDetailsLink(SEPHORA_LINK),
          element: <LazySephoraScraper />,
        },
        {
          path: ApiDetailsLink(FNAC_LINK),
          element: <LazyFnacScraper />,
        },
        {
          path: ApiDetailsLink(RAKUTEN_LINK),
          element: <LazyRakutenScraper />,
        },
        {
          path: ApiDetailsLink(AMAZON_LINK),
          element: <LazyAmazonScraper />,
        },
        {
          path: ApiDetailsLink(CDISCOUNT_LINK),
          element: <LazyCdiscountScraper />,
        },
        {
          path: ApiDetailsLink(FUNDA_LINK),
          element: <LazyFundaScraper />,
        },
        {
          path: ApiDetailsLink(PRESTASHOP_LINK),
          element: <LazyPrestashop />,
        },
        {
          path: ApiDetailsLink(YIELDBOOKING_LINK),
          element: <LazyYieldbooking />,
        },
        {
          path: ApiDetailsLink(TWITTER_LINK),
          element: <LazyTwitterScraper />,
        },
        {
          path: ApiDetailsLink(THREADS_LINK),
          element: <LazyThreadsScraper />,
        },
        {
          path: ApiDetailsLink(TIKTOK_LINK),
          element: <LazyTiktokScraper />,
        },
        {
          path: ApiDetailsLink(FACEBOOK_LINK),
          element: <LazyFacebookScraper />,
        },
        {
          path: ApiDetailsLink(INSTAGRAM_LINK),
          element: <LazyInstagramScraper />,
        },
        {
          path: ApiDetailsLink(LINKEDIN_LINK),
          element: <LazyLinkedInScraper />,
        },
        {
          path: ApiDetailsLink(REAL_ESTATE_LINK),
          element: <LazyRealEstateScraper />,
        },
        {
          path: ApiDetailsLink(ECOMMERCE_LINK),
          element: <LazyEcommerceScraper />,
        },
        {
          path: ApiDetailsLink(SEARCH_ENGINE_LINK),
          element: <LazySearchEngineScraper />,
        },
        {
          path: ApiDetailsLink(RAW_HTML_LINK),
          element: <LazyRawHtmlScraper />,
        },
        {
          path: BlogLink,
          element: <LazyBlog />,
        },
        {
          path: BlogPostLink(SPORTS_BETTING_POST_LINK),
          element: <LazyDataSportsBetting />,
        },
        {
          path: BlogPostLink(HOW_TO_SCRAPE_TWITTER_POST_LINK),
          element: <LazyHowToScrapeTwitter />,
        },
        {
          path: BlogPostLink(BEST_FREE_SCRAPING_TOOLS_POST_LINK),
          element: <LazyBestWebScrapingTools />,
        },
        {
          path: BlogPostLink(REAL_ESTATE_POST_LINK),
          element: <LazyScrapingBotRealEstate />,
        },
        {
          path: BlogPostLink(HOW_TO_SCRAPE_THREADS_POST_LINK),
          element: <LazyHowToScrapeThreads />,
        },
        {
          path: BlogPostLink(ECOMMERCE_POST_LINK),
          element: <LazyPricingWeb />,
        },
        {
          path: BlogPostLink(EMAIL_SCRAPING_POST_LINK),
          element: <LazyEmailScraping />,
        },
        {
          path: BlogPostLink(':postUrl'),
          element: <LazyBlogPost />,
        },
        {
          path: OnlineApiTestLink,
          element: <LazyOnlineApiTest />,
        },
        {
          path: DocumentationLink(':sectionPage?'),
          element: <LazyDocumentation />,
        },
        {
          path: ContactLink,
          element: <LazyContact />,
        },
        {
          path: PricingLink,
          element: <LazyPricing />,
        },
      ],
    },
    {
      path: 'error',
      element: <LazyErrorPage />,
    },
  ],
  {
    basename: BASENAME,
  }
);
