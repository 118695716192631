import {LinkedIn, Mail} from '@mui/icons-material';
import {Button, Divider, Grid, IconButton} from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import {lazy} from 'react';
import {Link as RouterLink} from 'react-router-dom';
import productHunt1 from '../assets/product-hunt-featured.webp';
import productHunt2 from '../assets/product-hunt-top-post.webp';
import {ContactLink} from '../utils/constants';
import {Link} from './Link';
import {Text} from './Text';
import {RenderOnScroll} from './RenderOnScroll';
import {Image} from './Image';
const FooterPosts = lazy(() => import('./FooterPosts'));

export const Footer = () => {
  return (
    <Box
      sx={{
        borderTop: '2px solid',
        borderColor: 'secondary.main',
        bgcolor: 'background.default',
      }}
    >
      <Container>
        <Grid container sx={{py: 4}}>
          <Grid item xs={12} md={4} p={1}>
            <Image
              src="logo.webp"
              alt="ScrapingBot"
              sizes={[300]}
              width={450}
              height={83}
              style={{width: 300}}
            />
            <Text sx={{py: 4, color: 'text.secondary'}}>
              Best web scraping APIs to extract HTML content without getting
              blocked.
            </Text>
            <Link
              href="https://www.producthunt.com/posts/scrapingbot?utm_source=badge-featured&amp;utm_medium=badge&amp;utm_souce=badge-scrapingbot"
              target="_blank"
              rel="noreferrer"
            >
              <img
                loading="lazy"
                src={productHunt1}
                alt="ScrapingBot - The API you need for efficient scraping! | Product Hunt Embed"
                width={250}
                height={54}
              />
            </Link>
            <Link
              href="https://www.producthunt.com/posts/scrapingbot?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-scrapingbot"
              target="_blank"
              rel="noreferrer"
            >
              <img
                loading="lazy"
                src={productHunt2}
                alt="ScrapingBot - The API you need for efficient scraping! | Product Hunt"
                width={250}
                height={54}
              />
            </Link>
          </Grid>
          <Grid item xs={12} md={4} p={1}>
            <Button
              component={RouterLink}
              color="secondary"
              to={`/${ContactLink}`}
              startIcon={<Mail />}
              sx={{color: 'text.primary'}}
            >
              Open Support Request
            </Button>
            <Text h6 component="p" sx={{pt: 4}}>
              Be Social
            </Text>
            <Divider sx={{width: 25, height: 2, bgcolor: '#2b8dd6', my: 1}} />
            <IconButton
              href="https://www.linkedin.com/company/scrapingbot/"
              target="_blank"
              rel="noreferrer"
              title="LinkedIn"
            >
              <LinkedIn
                fontSize="large"
                sx={{
                  color: 'text.primary',
                  '&:hover': {
                    color: 'secondary.main',
                  },
                }}
              />
            </IconButton>
          </Grid>
          <Grid item xs={12} md={4} p={1}>
            <Text h6 component="p">
              Random Posts
            </Text>
            <Divider sx={{width: 25, height: 2, bgcolor: '#2b8dd6', my: 1}} />
            <RenderOnScroll height={100}>
              <FooterPosts />
            </RenderOnScroll>
          </Grid>
        </Grid>
      </Container>
      <Text
        center
        sx={{bgcolor: 'background.paper', color: 'text.secondary', py: 2}}
      >
        ScrapingBot, a{' '}
        <Link href="https://sostag.fr/" target="_blank" color="text.primary">
          Sostag
        </Link>{' '}
        solution -{' '}
        <Link
          href="https://www.scraping-bot.io/terms-and-conditions-of-use/"
          color="text.primary"
        >
          Terms and conditions
        </Link>
      </Text>
    </Box>
  );
};
